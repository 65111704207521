import { format as formatting } from 'date-fns';

interface Params {
  date?: string;
  format?: string;
}

const getFormattedDate = ({ date, format }: Params) =>
  formatting(date ? new Date(date) : Date.now(), format ?? 'dd MMM yyyy');

export default getFormattedDate;
