import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';

const StarRatings = dynamic(() => import('react-star-ratings'), {
  ssr: false,
});

const Rating = ({ rating, starDimension, starSpacing, viewBox }) => {
  return (
    <StarRatings
      name={nanoid()}
      rating={rating}
      starDimension={starDimension}
      starSpacing={starSpacing}
      svgIconPath="M10.1365 2.05525C9.79719 1.0111 8.32 1.01109 7.98073 2.05525L6.59237 6.32816C6.57452 6.3831 6.52333 6.4203 6.46557 6.4203H1.97276C0.874874 6.4203 0.418386 7.82519 1.3066 8.47051L4.94136 11.1113C4.98809 11.1453 5.00765 11.2055 4.9898 11.2604L3.60144 15.5333C3.26218 16.5775 4.45725 17.4457 5.34546 16.8004L8.98022 14.1596C9.02695 14.1257 9.09023 14.1257 9.13696 14.1596L12.7717 16.8004C13.6599 17.4457 14.855 16.5775 14.5157 15.5333L13.1274 11.2604C13.1095 11.2055 13.1291 11.1453 13.1758 11.1113L16.8106 8.47052C17.6988 7.8252 17.2423 6.4203 16.1444 6.4203H11.6516C11.5939 6.4203 11.5427 6.3831 11.5248 6.32817L10.1365 2.05525Z"
      svgIconViewBox={viewBox}
      starEmptyColor="$gray-text"
      starRatedColor="var(--yellow)"
    />
  );
};

export default Rating;
