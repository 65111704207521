/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/naming-convention */
import { forwardRef } from 'react';

import cn from 'classnames';

import { PictureSourcesType } from '@/src/domain/common/ImageSizesType';

import type { PictureProps } from './interfaces';

import styles from './Picture.module.scss';

const base64Pixel =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

const Picture = forwardRef<HTMLImageElement, Omit<PictureProps, 'blurhash'>>((props, imageRef) => {
  const { noImageOnTouch = false, onLoad, onLoadError, alt = '', className = '', isLoaded } = props;

  const desktopImages = props.desktop || ({} as PictureSourcesType);
  const tabletImages = props.tablet || props.desktop || ({} as PictureSourcesType);
  const mobileImages = props.mobile || ({} as PictureSourcesType);

  const {
    x1: desktop_x1,
    x2: desktop_x2,
    webp_x1: desktop_webp_x1,
    webp_x2: desktop_webp_x2,
  } = desktopImages;
  const {
    x1: tablet_x1,
    x2: tablet_x2,
    webp_x1: tablet_webp_x1,
    webp_x2: tablet_webp_x2,
  } = tabletImages;
  const {
    x1: mobile_x1,
    x2: mobile_x2,
    webp_x1: mobile_webp_x1,
    webp_x2: mobile_webp_x2,
  } = mobileImages;

  return !Object.keys(props).length ? (
    //TODO: Add image fallback?
    // <img
    //   src='/images/error-page-image.png'
    //   alt={alt}
    // />
    <>Image was not loaded</>
  ) : desktop_x1 && desktop_x1.endsWith('.svg') ? (
    <img src={desktop_x1} alt={alt} />
  ) : (
    <picture>
      {noImageOnTouch && (
        <source
          media="(hover: none) and (pointer: coarse), (hover: none) and (pointer: fine)"
          srcSet={base64Pixel}
          sizes="100%"
        />
      )}
      <source
        type="image/webp"
        media="(min-width: 834px)"
        srcSet={`${desktop_webp_x1}, ${desktop_webp_x2} ${desktop_webp_x2 ? '2x' : ''}`}
      />
      <source
        media="(min-width: 834px)"
        srcSet={`${desktop_x1}, ${desktop_x2} ${desktop_x2 ? '2x' : ''}`}
      />
      <source
        type="image/webp"
        media="(min-width: 1px)"
        srcSet={`${tablet_webp_x1}, ${tablet_webp_x2} ${tablet_webp_x2 ? '2x' : ''}`}
      />
      <source
        media="(min-width: 1px)"
        srcSet={`${tablet_x1}, ${tablet_x2} ${tablet_x2 ? '2x' : ''}`}
      />

      {/* <source
        type='image/webp'
        media='(min-width: 1px)'
        srcSet={`${mobile_webp_x1}, ${mobile_webp_x2} ${mobile_webp_x2 ? '2x' : ''}`}
      />
      <source
        media='(min-width: 1px)'
        srcSet={`${mobile_x1}, ${mobile_x2} ${mobile_x2 ? '2x' : ''}`}
      /> */}
      <img
        ref={imageRef}
        src={desktop_x1}
        srcSet={`${desktop_x2 || desktop_x1} ${desktop_x2 ? '2x' : ''}`}
        crossOrigin=""
        className={cn(styles.image, className, { [styles['image--loaded']]: isLoaded })}
        alt={alt}
        onLoad={onLoad}
        onError={onLoadError}
      />
    </picture>
  );
});

Picture.displayName = 'Picture';
export default Picture;
