'use client';
import type { SyntheticEvent } from 'react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { Blurhash } from 'react-blurhash';

import { ImageSizesType } from '@/src/domain/common/ImageSizesType';

import { defaultBlurPlaceholder, defaultImageProps } from './config';
import type { LazyPictureProps } from './interfaces';
import Picture from './Picture';

import styles from './Picture.module.scss';

const LazyPicture = ({
  data,
  onLoadSuccess,
  className = '',
  containerClassName = '',
  ...props
}: LazyPictureProps) => {
  const blurhash = data?.blurhash || null;
  const [isLoaded, setIsLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState<ImageSizesType>(defaultImageProps);
  const imageRef = useRef<HTMLImageElement>(null);

  const imgPlaceholder = useMemo(() => blurhash || defaultBlurPlaceholder, [blurhash]);

  const onLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const target = event.target as HTMLImageElement;
    onLoadSuccess?.(target);
    setIsLoaded(true);
  };

  useEffect(() => {
    let observer: IntersectionObserver;
    if (IntersectionObserver) {
      observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.intersectionRatio > 0 || entry.isIntersecting) {
              data && setImageSrc(data);
              imageRef?.current && observer.unobserve(imageRef?.current);
            }
          });
        },
        {
          threshold: 0.01,
          rootMargin: '20%',
        },
      );
      imageRef?.current && observer.observe(imageRef?.current);
    } else {
      data && setImageSrc(data);
    }
    return () => {
      if (observer && observer.unobserve && imageRef?.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [data, imageRef]);

  return (
    <div className={cn(styles.container, containerClassName)}>
      <div
        className={cn(styles.container__blur_hash, {
          [styles['container__blur_hash--hidden']]: isLoaded,
        })}
      >
        <Blurhash
          hash={imgPlaceholder}
          width="100%"
          height="100%"
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </div>
      <Picture
        ref={imageRef}
        {...imageSrc}
        {...props}
        className={className}
        onLoad={onLoad}
        isLoaded={isLoaded}
      />
    </div>
  );
};

export default memo(LazyPicture);
