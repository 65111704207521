export const defaultImageProps = {
  desktop: {
    x1: '',
    x2: '',
    webp_x1: '',
    webp_x2: '',
  },
  tablet: {
    x1: '',
    x2: '',
    webp_x1: '',
    webp_x2: '',
  },
  mobile: {
    x1: '',
    x2: '',
    webp_x1: '',
    webp_x2: '',
  },
};

export const defaultBlurPlaceholder = 'LC0+oFfSfQfSo3fQfQfQn[fOfQfO';
