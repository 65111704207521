'use client';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { GAME_APP_ENDPOINT } from '@/src/api/endpoints/app';
import { GameType } from '@/src/domain/games/game/GameType';
import { widthBreakpoints } from '@/src/hooks/useResize';
import { useRouter } from '@/src/i18n/routing';
import getFormattedDate from '@/src/utils/getFormattedDate';

import Button from '../Button';
import { Picture } from '../Picture';
import { Rating } from '../Rating';

import styles from './GameCard.module.scss';

interface Props {
  className?: string;
  imageClassName?: string;
  rating?: number;
  type?: string;
  date?: string;
  tag?: string | null;
}

const GameCard = ({
  image,
  title,
  type,
  rating,
  className,
  imageClassName,
  slug,
  category,
  date,
  tag,
}: Partial<GameType> & Props) => {
  const router = useRouter();
  const t = useTranslations();
  const gameType = category?.title || type;
  const isGameReleased = date ? new Date(date) < new Date() : false;
  const isHaveLink = slug && tag !== 'in_progress' && tag !== 'coming_soon';

  const routerToGamePage = () => {
    if (isHaveLink) {
      router.push(GAME_APP_ENDPOINT.replace('[slug]', slug));
    }
  };

  // TODO
  const displayWidth = 1280;

  return (
    <div
      className={cn(styles.game_item, className, { [styles['game_item--pointer']]: isHaveLink })}
      role="button"
      tabIndex={isHaveLink ? 0 : -1}
      onClick={routerToGamePage}
    >
      <div
        className={cn(styles.game_item_image, imageClassName, {
          [styles.game_item_image__blured]: tag === 'coming_soon',
        })}
      >
        {tag === 'in_progress' && (
          <div className={styles.game_item_inProgress}>{t('profile_page.roadmap.in_progress')}</div>
        )}
        {tag === 'coming_soon' && (
          <div className={styles.game_item_commingSoon}>
            {t('profile_page.roadmap.coming_soon')}
          </div>
        )}

        {image?.desktop?.webp_x1 && (
          // <Image
          //   src={image.desktop.webp_x1}
          //   width="279px"
          //   height="279px"
          //   alt={title}
          //   placeholder="blur"
          //   blurDataURL={image.desktop.webp_x1}
          // />
          <Picture
            data={image}
            // className={styles.game_item__image}
            containerClassName={styles.game_item_image_container}
          />
        )}
        <div className={styles.game_item__button}>
          <Button text={t('buttons.play')} />
        </div>
      </div>

      <div className={styles.game_item_content}>
        {rating && (
          <div className={styles.game_item_rating}>
            <div className={styles.game_item_rating_stars}>
              <Rating
                rating={rating}
                starDimension={`${displayWidth < widthBreakpoints.mobile_xl ? '13px' : '18px'}`}
                starSpacing="0"
                viewBox={`0 0 ${displayWidth < widthBreakpoints.mobile_xl ? 21 : 18} ${
                  displayWidth < widthBreakpoints.mobile_xl ? 21 : 18
                }`}
              />
            </div>
            <div className={styles.game_item_rating_number}>{rating?.toFixed(1)}</div>
          </div>
        )}
        <div
          className={cn(styles.game_item_title, {
            [styles.game_item_title_withoutRating]: !rating,
          })}
        >
          {title}
        </div>

        {gameType ? <p className={styles.game_item__extra_info}>{gameType}</p> : <></>}
        {date ? (
          <p className={styles.game_item__extra_info}>{`${t(
            isGameReleased
              ? 'profile_page.roadmap.gameDate.release'
              : 'profile_page.roadmap.gameDate.expected',
          )} ${getFormattedDate({ date })}`}</p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default GameCard;
